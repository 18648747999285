import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DivisionOrders = () => (
  <Layout>
    <SEO title="Division Orders" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-blue-main">Division Orders</h1>
        <div className="row">
          <div className="col-lg-8">
            <p>
              Division Orders, DOs, or Division of Interest documents are
              important components of any E&P business. To ensure that these
              documents are processed and managed very well, Pacific Coast Land
              Solutions provides a well-rounded set of Division Orders-related
              services.
            </p>
            <p>
              Our team is composed of highly qualified professionals who can
              provide administrative and consulting assistance. Detailing of
              proportional ownerships of minerals and the distribution of oil,
              natural gas, and other major energy products can be done in a more
              efficient manner through our DO services.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <h2 className="my-3">Division Orders Services We Offer:</h2>
            <p>
              Here are the numerous ways we can help you when it comes to
              Division Orders. To know more about these services, feel free to
              send us an email at any time.
            </p>
          </div>
          <div className="col-lg-10 ml-lg-3">
            <ul>
              <li>
                <b className="text-blue-main">Division Orders</b> - We can help
                you set up a Division of Interest (DOI) deck. This will reflect
                the entirety of the property with clear details and accurate
                measurements (lease, tract, well data, lease working interest,
                lease royalty rate, tract mineral interest, etc.)
              </li>
              <li>
                <b className="text-blue-main">Division Order Document</b> -
                Division Order documents or reports are critical and must be
                done without errors that can become extremely problematic later
                on. We will assist you with this by making sure all necessary
                fields within the document contain the needed information, such
                as Unit Description, Well Name, the Net Revenue Interest (NRI),
                etc. Since Division Orders are meant to give notice to owners
                and governmental agencies, they need to be done with due
                diligence.
              </li>
              <li>
                <b className="text-blue-main">Send Out Division Orders</b> -
                Mailing DO packets is another matter we can help you with. to
                each owner can then be mailed out. We will send these on your
                behalf in a timely and organized manner while ensuring peace of
                mind. Investigation - We perform cross-referencing and other
                checks to ensure data accuracy. The whole verification process
                makes sure that landowners receive their proper production
                share.
              </li>
              <li>
                <b className="text-blue-main">Interest Calculation</b> - For
                this complex process, vigilance and accuracy are required. The
                calculated results will be used for sorting out payments and
                making sure the right amount is owed by the right people. This
                also requires the close look of a Division Order Analyst as well
                as monitoring transfer of interests. Pacific Coast can help make
                every step more stress-free.
              </li>
              <li>
                <b className="text-blue-main">Accounting Updates</b> - Update
                accounts, sort information, fill up forms, and coordinate with
                in-house and third-party professionals smoothly. Validation and
                Setup - Manage the updates of ownership information and
                addresses and have them verified. Make sure that you have an
                overview of the status of your DOs any time.
              </li>
              <li>
                <b className="text-blue-main">Tracking during Wait Periods</b> -
                There will be wait periods in between each step that a Division
                Order goes through. If not monitored well, things can fall
                through the cracks and cause huge delays or irreparable damage.
                Avoid this by having professionals track whatever is needed and
                supervise waiting timelines.
              </li>
              <li>
                <b className="text-blue-main">Deck Updates</b> - As Division
                Orders are executed, decks will naturally have to be updated.
                Keep on top of everything without stressing too much. We can
                also help you during times when regulations are updated and
                decks need to be kept up to date.
              </li>
              <li>
                <b className="text-blue-main">
                  Payments Management and Maintenance
                </b>
                - Once DOs are received and decks have been updated, it is time
                to cut those checks. This service can assist your accounting
                team in making sure that payments are sorted properly and any
                administrative maintenance or preventive measures are done to
                the highest standards.
              </li>
            </ul>
            <p>
              Let us know how we can help you with your Division Orders. We are
              more than happy to provide tailor-made solutions. Contact us via
              these channels:
              <a
                href="mailto:info@pacificcoastls.com"
                className="text-blue-main mx-1"
              >
                info@pacificcoastls.com
              </a>
              or call us:
              <a href="tel:720.234.1155" className="text-blue-main mx-1">
                720.234.1155
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DivisionOrders
